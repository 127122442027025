import {
  CircularProgress,
  createTheme,
  Divider,
  Grid,
  Paper,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./orders.styles.scss";

import { useNavigate } from "react-router-dom";
import MetricBoxComponent from "./metricBox.component";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a36e29",
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },

  background: {
    paper: "#a36e29",
  },
  text: {
    primary: "#a36e29",
    secondary: "#a36e29",
  },
  action: {
    active: "#a36e29",
  },
  success: {
    dark: "#a36e29",
  },
});

const OrdersComponent = ({ row }) => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const [ordersList, setOrdersList] = useState([]);
  const [orderStats, setOrderStats] = useState({});

  useEffect(() => {
    (async () => {
      if (!admin.token) return;
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1.0.0/admin/orders/all.php?type=all_admin_orders`,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setOrdersList(
        data.response?.order_list?.sort((a, b) => {
          return new Date(b?.updated_at) - new Date(a?.updated_at);
        }) || []
      );
      setOrderStats(data.response.dashboard_details);
    })();
  }, []);

  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showDrawer, setShowDrawer] = useState(false);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatPrice = (price) => {
    if (price >= 10000000) {
      return `${(price / 10000000).toFixed(2)} Cr`;
    } else if (price >= 100000) {
      return `${(price / 100000).toFixed(2)} L`;
    } else if (price >= 1000) {
      return `${(price / 1000).toFixed(2)}k`;
    }
    return price.toString();
  };

  return (
    <div className="category-component">
      <ToastContainer />

      {/* Swipable Drawer */}
      <ThemeProvider theme={theme}>
        <SwipeableDrawer
          anchor="right"
          open={showDrawer}
          onClose={() => {
            setShowDrawer(false);
          }}
          onOpen={() => setShowDrawer(true)}
        ></SwipeableDrawer>
      </ThemeProvider>

      {/* Main Content */}
      <div className="head">
        <div className="head-txt">Orders</div>
      </div>

      <Grid
        container
        spacing={3}
        style={{
          width: "100%",
          height: "300px",
          paddingLeft: "25px",
          marginBottom: "25px",
        }}
      >
        <Grid item xs={12 / 4}>
          <MetricBoxComponent
            heading={"Total Orders"}
            metric={orderStats.total_orders || "0"}
          />
        </Grid>
        <Grid item xs={12 / 4}>
          <MetricBoxComponent
            heading={"Total Sales"}
            metric={
              orderStats.total_price
                ? `₹${formatPrice(parseInt(orderStats.total_price))}`
                : "₹0"
            }
          />
        </Grid>

        <Grid item xs={12 / 4}>
          <MetricBoxComponent
            heading={"Orders Completed"}
            metric={orderStats.completed_orders || "0"}
          />
        </Grid>
        <Grid item xs={12 / 4}>
          <MetricBoxComponent
            heading={"Orders Refunded"}
            metric={orderStats.refunded_orders || "0"}
          />
        </Grid>
      </Grid>
      <Divider />

      <ThemeProvider theme={theme}>
        <Paper
          sx={{
            width: "95%",
            overflow: "hidden",
            height: "50vh",
            margin: "30px 25px",
            borderRadius: "10px",
            boxShadow: "0 0 10px 1px rgb(241, 241, 241)",
            paddingLeft: "10px",
          }}
        >
          {ordersList.length === 0 ? (
            <CircularProgress
              style={{
                margin: "auto",
                display: "flex",
                height: "100%",
              }}
            />
          ) : (
            <TableContainer
              style={{
                height: "90%",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order Id</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Order</TableCell>
                    <TableCell>Seller</TableCell>
                    <TableCell>Total Price</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersList?.length > 0 &&
                    ordersList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <Fragment key={row.id}>
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                              onClick={() =>
                                navigate(`/order-detail/${row.order_record_id}`)
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <TableCell>
                                {row?.order_detail_items[0]["public_id"]}
                              </TableCell>
                              <TableCell>{row?.user_name}</TableCell>
                              <TableCell>
                                {new Date(row?.created_at).toLocaleString()}
                              </TableCell>
                              <TableCell>
                                {row?.order_detail_items.length} Product(s)
                              </TableCell>
                              <TableCell>
                                {row?.order_detail_items.length > 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/assets/${row.order_detail_items[0].company_logo}`}
                                      alt={
                                        row.order_detail_items[0].company_name
                                      }
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        marginRight: "10px",
                                      }}
                                    />
                                    <div>
                                      {row.order_detail_items[0].company_name}
                                      {row.order_detail_items.length > 1 &&
                                        row.order_detail_items.some(
                                          (item) =>
                                            item.company_name !==
                                            row.order_detail_items[0]
                                              .company_name
                                        ) && <div>and 1 more</div>}
                                    </div>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell>₹ {row?.order_price}</TableCell>
                              <TableCell
                                style={{
                                  fontWeight: 800,
                                  color:
                                    row?.shipment_status === "ADMIN_VERIFIED"
                                      ? "green"
                                      : row?.shipment_status ===
                                          "PARTIALLY_CANCELED" ||
                                        row?.shipment_status ===
                                          "PARTIALLY_RETURNED"
                                      ? "brown"
                                      : row?.shipment_status ===
                                          "ORDER_CANCELED" ||
                                        row?.shipment_status === "RETURNED"
                                      ? "red"
                                      : "gray",
                                }}
                              >
                                {row?.shipment_status === "ADMIN_VERIFIED"
                                  ? "Fullfilled"
                                  : row?.shipment_status ===
                                    "PARTIALLY_CANCELED"
                                  ? "Partially Cancelled"
                                  : row?.shipment_status === "ORDER_CANCELED"
                                  ? "Cancelled"
                                  : row?.shipment_status ===
                                    "PARTIALLY_RETURNED"
                                  ? "Partially Returned"
                                  : row?.shipment_status === "RETURNED"
                                  ? "Returned"
                                  : "Unfullfilled"}
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 200]}
            component="div"
            count={ordersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </ThemeProvider>
    </div>
  );
};

export default OrdersComponent;
