import { Divider } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomDrawer from "../../components/drawer/drawer.component";
import ReviewComponent from "../../components/reviews/review.component";
import "./reviewsPage.styles.scss";

import { generalToastStyle } from "../../utils/toast.styles";

const ReviewsPage = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const [reviews, setReviews] = useState([]);

  const getReviews = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}admin/product/reviews.php?type=all&page=1&page_size=100`,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      )
      .then((response) => {
        setReviews(response?.data?.response?.reviews);
        console.log(response?.data?.response);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error?.response?.data?.message, generalToastStyle);
      });
  }, [admin.token]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  return (
    <div className="product">
      <CustomDrawer section={"reviews"} />
      <Divider orientation="vertical" className="divider" />
      <div className="component">
        <ReviewComponent row={reviews} refresh={() => getReviews()} />
      </div>
    </div>
  );
};

export default ReviewsPage;
