import { Delete } from "@mui/icons-material";
import {
  Avatar,
  Box,
  createTheme,
  Divider,
  ImageList,
  ImageListItem,
  Paper,
  Rating,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
  Modal,
  Button,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {  Done } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";

import "./review.styles.scss";

import { generalToastStyle } from "../../utils/toast.styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a36e29",
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
});

function srcset(image, size, rows = 1, cols = 1) {
  console.log(`${process.env.REACT_APP_API_URL}assets/${image}`);
  return {
    src: `${process.env.REACT_APP_API_URL}assets/${image}`,
  };
}

const ReviewComponent = ({ row, refresh }) => {
  const admin = JSON.parse(localStorage.getItem("admin"));

  const [showLoading, setShowLoading] = useState(false);
  const [selectedReview, setSelectedReview] = useState({});

  const [categories, setCategories] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDrawer, setShowDrawer] = useState(false);
  const [deleteReviewsModal, setDeleteReviewsModal] = useState(false);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteReview = (targetId) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}admin/product/reviews.php`,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
          data: {
            id: targetId,
          },
        }
      )
      .then((_) => {
        refresh();
      })
      .catch((error) => {
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const approveReview = (targetId) => {
    axios
      .put(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/reviews.php",
        {
          type: "flag_reviews",
          id: targetId,
          flag_review: false,
          flag_reason: "Inappropriate Review",
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      )
      .then((_) => {
        refresh();
      });
  };

  const rowClicked = (row) => {
    const a = row.image_url.split('","');
    console.log("here is a", a[0].slice(2));
    setSelectedReview(row);
    setShowDrawer(true);
  };

  return (
    <div className="category-component">
      <Modal open={deleteReviewsModal} onClose={() => setDeleteReviewsModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            width: 400,
            backgroundColor: "white",
            p: 4,
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              marginBottom: "20px",
              fontFamily: '"Open Sans", sans-serif',
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            Are you sure you want to delete this review?
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <Button
              variant="outlined"
              style={{
                width: "48%",
                fontWeight: "bold",
                border: "2px solid #a36e29",
                color: "#a36e29",
                fontFamily: '"Open Sans", sans-serif',
                fontSize: "0.8rem",
              }}
              onClick={() => setDeleteReviewsModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                width: "48%",
                fontWeight: "bold",
                background: "#a36e29",
                fontFamily: '"Open Sans", sans-serif',
                fontSize: "0.8rem",
              }}
              onClick={() => {
                deleteReview(selectedReview.id);
                setDeleteReviewsModal(false);
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <ToastContainer />

      {/* Swipable Drawer */}
      <ThemeProvider theme={theme}>
        <SwipeableDrawer
          anchor="right"
          open={showDrawer}
          onClose={() => {
            setShowLoading(false);

            setShowDrawer(false);
          }}
          onOpen={() => setShowDrawer(true)}
        >
          <div
            style={{
              padding: "20px",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                fontFamily: '"Work Sans", sans-serif',
                marginBottom: "40px",
              }}
            >
              View User Review
            </div>
            <Box
              style={{
                maxWidth: "30vw",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "2%",
                }}
              >
                <Avatar sx={{ bgcolor: "gray" }} alt="Gemy Sharp">
                  {selectedReview?.name?.slice(0, 1)?.toUpperCase()}
                </Avatar>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    marginLeft: "2%",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#606060",
                      fontSize: "1rem",
                    }}
                  >
                    {selectedReview?.name || "Gordon Ramsey"}
                  </Typography>
                  <Typography style={{ color: "#a0a0a0" }}>
                    {selectedReview?.created_at}
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Rating
                  name="size-large"
                  value={parseInt(selectedReview?.rating || 2)}
                  size="large"
                  readOnly
                />
                <Typography style={{ fontSize: "1.4rem" }}>
                  {selectedReview?.rating}/5
                </Typography>
              </Box>

              <Typography
                variant="subtitle"
                style={{
                  textAlign: "justify",
                  marginTop: "10%",
                  marginBottom: "20px",
                  fontWeight: 600,
                  fontSize: "1.5rem",
                }}
              >
                {selectedReview?.title}
              </Typography>

              <Typography
                variant="subtitle"
                style={{
                  textAlign: "justify",
                  marginTop: "10px",
                  marginBottom: "5%",
                }}
              >
                {selectedReview?.content}
              </Typography>

              <ImageList
                sx={{ width: "100%", height: 400 }}
                variant="quilted"
                cols={4}
                rowHeight={121}
              >
                {selectedReview?.image_url?.length > 0
                  ? JSON.parse(selectedReview?.image_url)?.map((item) => (
                    <ImageListItem key={item} cols={1} rows={1}>
                      <img {...srcset(item, 121, 0, 0)} loading="lazy" />
                    </ImageListItem>
                  ))
                  : null}
              </ImageList>
            </Box>
          </div>
        </SwipeableDrawer>
      </ThemeProvider>

      {/* Main Content */}
      <div className="head">
        <div className="head-txt">Reviews</div>
      </div>
      <Divider />

      <ThemeProvider theme={theme}>
        <Paper className="table-paper">
          <Fragment>
            <TableContainer
              style={{
                height: "calc(100% - 50px)",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Rating</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Review</TableCell>
                    <TableCell>Images</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.map((row) => {
                    return (
                      <Fragment key={row.id}>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell
                            onClick={() => rowClicked(row)}
                          ></TableCell>
                          <TableCell onClick={() => rowClicked(row)}>
                            {row.name || ""}
                          </TableCell>
                          <TableCell onClick={() => rowClicked(row)}>
                            {row.rating}/5{" "}
                          </TableCell>
                          <TableCell onClick={() => rowClicked(row)}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={`${process.env.REACT_APP_API_URL}assets/${row.product_image.file}`}
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "10px",
                                  marginRight: "15px",
                                }}
                              />
                              {row.product_name || ""}
                            </div>
                          </TableCell>
                          <TableCell onClick={() => rowClicked(row)}>
                            {row.content.substring(0, 60)}
                            {row.content.length > 60 ? "......" : ""}
                          </TableCell>
                          <TableCell onClick={() => rowClicked(row)}>
                            <div
                              style={{
                                width: "120px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {row?.image_url?.length > 0
                                ? JSON.parse(row?.image_url)
                                  .slice(0, 2)
                                  .map((item) => (
                                    <img
                                      {...srcset(item, 10)}
                                      loading="lazy"
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  ))
                                : null}
                            </div>
                          </TableCell>
                          <TableCell className="actions-content">
                            {row.flagged == "1" ? (
                              <Done
                                className="allow"
                                onClick={() => approveReview(row.id)}
                              />
                            ) : null}
                            <Delete
                              className="delete"
                              onClick={() => {
                                setDeleteReviewsModal(true);
                                setSelectedReview(row);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              component="div"
              count={row.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Fragment>
        </Paper>
      </ThemeProvider>
    </div>
  );
};

export default ReviewComponent;
