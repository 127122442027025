import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { generalToastStyle } from "../../utils/toast.styles";
import CustomerDetailComponent from "./customerDetail.component";
import OrderSummaryComponent from "./orderSummary.component";
import SellerDetailComponent from "./sellerDetail.component";
import TrackOrderComponent from "./trackOrder.component";

const OrderInfoCard = ({ orderDetail }) => {
  return (
    <div
      style={{
        width: "100%",
        marginTop: "30px",
        height: "max-content",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "160px",
          height: "160px",
          borderRadius: "12px",
          border: "1px solid #e7e7e7",
          overflow: "hidden",
        }}
      >
        <img
          src={`${process.env.REACT_APP_API_URL}assets/${orderDetail.images[0]["file"]}`}
          alt=""
          style={{ width: "100%" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          height: "160px",
          width: "50%",
          display: "flex",
          flexDirection: "column",
          marginLeft: "30px",
        }}
      >
        <div style={{ margin: 0, fontSize: "1.4rem", fontWeight: 600 }}>
          {orderDetail?.product_name}
        </div>
        <div
          style={{
            margin: 0,
            marginTop: "10px",
            color: "gray",
            lineHeight: "2rem",
            fontWeight: 500,
            fontSize: "1.1rem",
          }}
        >
          Quantity: {orderDetail?.quantity} pcs.
        </div>
        <p
          style={{
            margin: 0,
            color: "gray",
            fontWeight: 500,
            lineHeight: "2rem",
            fontSize: "1.1rem",
          }}
        >
          Price :{" "}
          <span style={{ color: "black" }}> Rs: {orderDetail?.price}</span>
        </p>
        <p
          style={{
            margin: 0,
            color: "gray",
            fontWeight: 500,
            lineHeight: "2rem",
          }}
        >
          Deliver By: {Date(orderDetail?.estimated_date)}
        </p>
      </div>
    </div>
  );
};

const OrderDetail = ({ id }) => {
  const admin = JSON.parse(localStorage.getItem("admin"));

  const [open, setOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [logistics, setLogistics] = useState("");
  const [cancleOrderSwitch, setCancleOrderSwitch] = useState(false);
  const [logs, setLogs] = useState();
  const [openReceivedDialog, setOpenReceivedDialog] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [selectedProductForCancellation, setSelectedProductForCancellation] =
    useState(null);
  const [refundOrderId, setRefundOrderId] = useState(null);
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [openTrackingDialog, setOpenTrackingDialog] = useState(false);
  const [shippingPartner, setShippingPartner] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [returnOrderId, setReturnOrderId] = useState(null);
  const [returnReceivedOrderId, setReturnReceivedOrderId] = useState("");

  const navigate = useNavigate();

  const deliveryPartners = [
    {
      name: "Sequel Logistics",
      value: "sequel",
    },
    {
      name: "Delhivery Logistics",
      value: "delhivery",
    },
  ];

  useEffect(() => {
    (async () => {
      if (!admin.token) return;
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1.0.0/seller/orders/all.php?type=order_details&order_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setOrderDetails(data?.response);

      const shipmentDetails = {};
      data?.response.forEach((item) => {
        const parsedDetails = JSON.parse(item.shipment_details);
        Object.entries(parsedDetails).forEach(([timestamp, status]) => {
          shipmentDetails[timestamp] = status;
        });
      });

      const uniqueShipmentDetails = {};
      Object.entries(shipmentDetails)
        .sort((a, b) => new Date(b[0]) - new Date(a[0]))
        .forEach(([timestamp, status]) => {
          if (!Object.values(uniqueShipmentDetails).includes(status)) {
            uniqueShipmentDetails[timestamp] = status;
          }
        });

      setLogs(uniqueShipmentDetails);
    })();
  }, []);

  // TODO
  const handleCancelOrder = async () => {
    if (!logistics || !cancellationReason) return;

    const formData = new FormData();
    formData.append("type", "admin_cancel");
    formData.append(
      "order_detail_id",
      selectedProductForCancellation?.order_detail_id
    );
    formData.append("logistics", logistics);
    formData.append("tracking_number", trackingNumber);
    formData.append("reason", cancellationReason);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}v1.0.0/admin/orders/all.php`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data?.success === 0) {
        toast(data.message, generalToastStyle);
      } else {
        navigate("/orders");
      }
    } catch (err) {
      toast(err?.response?.data?.message, generalToastStyle);
    }
  };

  const handleFulfillOrder = async () => {
    if (cancleOrderSwitch) {
      await handleCancelOrder();
      return;
    }
    if (!logistics) return;

    const formData = new FormData();
    formData.append("type", "create_customer_shipment");
    formData.append("order_record_id", id);
    formData.append("logistics", logistics);
    formData.append("tracking_id", trackingNumber);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1.0.0/admin/orders/all.php`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("data================>", data);
      if (data?.success === 0) {
        toast(data.message, generalToastStyle);
      } else {
        navigate("/orders");
      }
    } catch (err) {
      console.log(err?.response?.data?.message, "====================error");
      toast(err?.response?.data?.message, generalToastStyle);
    }
  };

  const handleClose = () => {
    setSelectedProductForCancellation(null);
    setOpen(false);
  };

  // Receive Product
  const receiveProduct = async (orderDetail) => {
    const formData = new FormData();
    formData.append("type", "admin_received");
    formData.append("order_detail_id", orderDetail?.order_detail_id);

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0.0/admin/orders/all.php`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${admin.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (data?.success === 0) {
      toast(data.message, generalToastStyle);
    } else {
      (async () => {
        if (!admin.token) return;
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1.0.0/seller/orders/all.php?type=order_details&order_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${admin.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setOrderDetails(data?.response);

        setLogs(JSON.parse(data?.response[0]?.shipment_details));

        setOpenReceivedDialog(false);
      })();
    }
  };

  // Create Refund
  const createRefund = async () => {
    try {
      const formData = new FormData();
      formData.append("type", "refund_order");
      formData.append("order_detail_id", refundOrderId);
      formData.append("refund_amount", refundAmount);

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1.0.0/admin/orders/all.php`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data?.success === 0) {
        toast(data.message, generalToastStyle);
      } else {
        toast("Refund created successfully", generalToastStyle);
        setRefundDialogOpen(false);
        setRefundAmount("");
        window.location.reload();
      }
    } catch (err) {
      toast(err?.response?.data?.message, generalToastStyle);
    }
  };

  const addReturnTrackingDetails = async () => {
    try {
      const formData = new FormData();
      formData.append("type", "fetch_customer_to_admin_package");
      formData.append("order_detail_id", returnOrderId);
      formData.append("logistics", shippingPartner);
      formData.append("tracking_number", trackingId);
      formData.append("reason", "Return accepted");

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1.0.0/admin/orders/all.php`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data?.success === 0) {
        toast(data.message, generalToastStyle);
      } else {
        toast("Tracking details added successfully", generalToastStyle);
        setOpenTrackingDialog(false);
        setTrackingId("");
        setShippingPartner("");
        window.location.reload();
      }
    } catch (err) {
      toast(err?.response?.data?.message, generalToastStyle);
    }
  };

  const markReturnReceived = async () => {
    console.log("markReturnReceived");

    try {
      const formData = new FormData();
      formData.append("type", "admin_received_by_customer");
      formData.append("order_detail_id", returnReceivedOrderId);

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1.0.0/admin/orders/all.php`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data?.success === 0) {
        toast(data.message, generalToastStyle);
      } else {
        toast("Return received successfully", generalToastStyle);
        window.location.reload();
      }
    } catch (err) {
      toast(err?.response?.data?.message, generalToastStyle);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        height: "max-content",
        backgroundColor: "#F8F5F0",
      }}
    >
      <ToastContainer />
      <Dialog
        maxWidth={"lg"}
        style={{
          width: "100%",
          height: "max-content",
          margin: "auto",
        }}
        open={open && !cancleOrderSwitch}
        onClose={handleClose}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            height: "max-content",
            width: "759px",
            borderRadius: "10px",
            fontFamily: '"Work Sans", sans-serif',
          }}
        >
          <p style={{ fontSize: "1.7rem", margin: 0, fontWeight: "bold" }}>
            Fulfill Order
          </p>
          <div style={{ width: "100%", height: "300px", overflowY: "scroll" }}>
            {orderDetails
              .filter(
                (orderData) => orderData.shipment_status === "ADMIN_RECEIVED"
              )
              .map((orderData) => (
                <OrderInfoCard
                  key={orderData.order_detail_id}
                  orderDetail={orderData}
                />
              ))}
          </div>
          <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
            Tracking Information
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "47%" }}>
              <p style={{ color: "gray", fontWeight: 600 }}>Tracking Number</p>
              <TextField
                fullWidth
                onChange={(e) => setTrackingNumber(e.target.value)}
              ></TextField>
            </div>
            <FormControl style={{ width: "47%" }}>
              <p style={{ color: "gray", fontWeight: 600 }}>
                Logistics Partner
              </p>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                onChange={(event) => {
                  setLogistics(
                    () =>
                      deliveryPartners.find(
                        (e) => e["name"] === event.target.value
                      )["value"]
                  );
                }}
                input={<OutlinedInput label="Name" />}
              >
                {deliveryPartners.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: "100%", display: "flex" }}>
            <Button
              style={{
                marginLeft: "auto",
                width: "250px",
                height: "70px",
                color: "white",
                backgroundColor: "#A36E29",
                fontWeight: 600,
                marginTop: "20px",
              }}
              onClick={handleFulfillOrder}
            >
              Fulfill Order
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        maxWidth={"lg"}
        style={{
          width: "100%",
          height: "max-content",
          margin: "auto",
        }}
        open={open && cancleOrderSwitch}
        onClose={handleClose}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            height: "max-content",
            width: "759px",
            borderRadius: "10px",
            fontFamily: '"Work Sans", sans-serif',
          }}
        >
          <p style={{ fontSize: "1.7rem", margin: 0, fontWeight: "bold" }}>
            Cancel Order
          </p>
          {!selectedProductForCancellation ? (
            <>
              <p
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  marginTop: "20px",
                }}
              >
                Select a product to cancel
              </p>
              <div
                style={{ width: "100%", height: "400px", overflowY: "scroll" }}
              >
                {orderDetails
                  .filter(
                    (orderData) =>
                      orderData.shipment_status === "ADMIN_RECEIVED"
                  )
                  .map((orderData) => (
                    <div
                      key={orderData.id}
                      onClick={() =>
                        setSelectedProductForCancellation(orderData)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <OrderInfoCard orderDetail={orderData} />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <div style={{ width: "100%", marginBottom: "20px" }}>
                <OrderInfoCard orderDetail={selectedProductForCancellation} />
              </div>
              <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                Tracking Information
              </p>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "47%" }}>
                  <p style={{ color: "gray", fontWeight: 600 }}>
                    Tracking Number
                  </p>
                  <TextField
                    fullWidth
                    onChange={(e) => setTrackingNumber(e.target.value)}
                  ></TextField>
                </div>
                <FormControl style={{ width: "47%" }}>
                  <p style={{ color: "gray", fontWeight: 600 }}>
                    Logistics Partner
                  </p>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    onChange={(event) => {
                      setLogistics(
                        () =>
                          deliveryPartners.find(
                            (e) => e["name"] === event.target.value
                          )["value"]
                      );
                    }}
                    input={<OutlinedInput label="Name" />}
                  >
                    {deliveryPartners.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ width: "100%", marginTop: "20px" }}>
                <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  Cancellation Reason
                </p>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  placeholder="Please provide a reason for cancellation"
                  onChange={(e) => setCancellationReason(e.target.value)}
                />
              </div>
              <div style={{ width: "100%", display: "flex" }}>
                <Button
                  style={{
                    marginLeft: "auto",
                    width: "250px",
                    height: "70px",
                    color: "white",
                    backgroundColor: "#A36E29",
                    fontWeight: 600,
                    marginTop: "20px",
                  }}
                  onClick={handleCancelOrder}
                >
                  Cancel Order
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>

      <Dialog
        maxWidth={"lg"}
        style={{
          width: "100%",
          height: "max-content",
          margin: "auto",
        }}
        open={openReceivedDialog}
        onClose={() => setOpenReceivedDialog(false)}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            height: "max-content",
            width: "759px",
            borderRadius: "10px",
          }}
        >
          <p style={{ fontSize: "1.7rem", margin: 0, fontWeight: "bold" }}>
            Receive Order
          </p>
          <div style={{ width: "100%", overflowY: "scroll" }}>
            {orderDetails
              .filter(
                (orderDetail) =>
                  orderDetail.shipment_status === "SELLER_VERIFIED"
              )
              .map((orderDetails) => (
                <div
                  style={{
                    width: "100%",
                    marginTop: "30px",
                    height: "max-content",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "130px",
                      height: "130px",
                      borderRadius: "12px",
                      border: "1px solid #e7e7e7",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/assets/${orderDetails?.images[0]["file"]}`}
                      alt=""
                      style={{
                        borderRadius: "12px",
                        height: "130px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: "130px",
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "30px",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: "1.4rem",
                        fontWeight: 600,
                        fontFamily: '"Work Sans", sans-serif',
                      }}
                    >
                      {orderDetails?.product_name}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        color: "gray",
                        fontWeight: 500,
                        lineHeight: "2rem",
                        fontSize: "1.1rem",
                        fontFamily: '"Work Sans", sans-serif',
                      }}
                    >
                      Price :{" "}
                      <span style={{ color: "black" }}>
                        {" "}
                        Rs. {orderDetails?.price}
                      </span>
                    </p>
                    <p
                      style={{
                        margin: 0,
                        color: "gray",
                        fontWeight: 500,
                        lineHeight: "2rem",
                        fontFamily: '"Work Sans", sans-serif',
                      }}
                    >
                      Deliver By: {Date(orderDetails?.estimated_date)}
                    </p>
                  </div>
                  {orderDetails?.shipment_status === "ADMIN_RECEIVED" ? (
                    <Button
                      variant="outlined"
                      style={{
                        marginLeft: "auto",
                        marginTop: "auto",
                        width: "180px",
                        height: "52px",
                        textTransform: "none",
                        fontSize: "1rem",
                        fontWeight: 600,
                        fontFamily: '"Work Sans", sans-serif',
                        border: "none",
                        color: "#A36E29",
                      }}
                    >
                      Marked as Received
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{
                        marginLeft: "auto",
                        marginTop: "auto",
                        width: "180px",
                        height: "52px",
                        backgroundColor: "#A36E29",
                        textTransform: "none",
                        fontSize: "1rem",
                        fontWeight: 600,
                        fontFamily: '"Work Sans", sans-serif',
                      }}
                      onClick={() => {
                        receiveProduct(orderDetails);
                      }}
                    >
                      Received
                    </Button>
                  )}
                </div>
              ))}
          </div>
        </div>
      </Dialog>

      <Dialog
        open={openTrackingDialog}
        onClose={() => setOpenTrackingDialog(false)}
        maxWidth={"lg"}
        style={{
          width: "100%",
          height: "max-content",
          margin: "auto",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            height: "max-content",
            width: "759px",
            borderRadius: "10px",
            fontFamily: '"Work Sans", sans-serif',
          }}
        >
          <p
            style={{
              fontSize: "1.7rem",
              margin: 0,
              fontWeight: "bold",
            }}
          >
            Add Return Tracking Details
          </p>

          <div style={{ marginTop: "30px" }}>
            {orderDetails
              ?.filter(
                (order) => order.shipment_status === "ORDER_RETURN_REQUEST"
              )
              .map((orderDetails) => (
                <OrderInfoCard
                  key={orderDetails.order_detail_id}
                  orderDetail={orderDetails}
                />
              ))}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <div style={{ width: "47%" }}>
              <p style={{ color: "gray", fontWeight: 600 }}>Tracking Number</p>
              <TextField
                fullWidth
                value={trackingId}
                onChange={(e) => setTrackingId(e.target.value)}
              ></TextField>
            </div>
            <FormControl style={{ width: "47%" }}>
              <p style={{ color: "gray", fontWeight: 600 }}>
                Logistics Partner
              </p>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={
                  deliveryPartners.find((p) => p.value === shippingPartner)
                    ?.name || ""
                }
                onChange={(event) => {
                  const partner = deliveryPartners.find(
                    (e) => e.name === event.target.value
                  );
                  setShippingPartner(partner?.value || "");
                }}
                input={<OutlinedInput label="Name" />}
              >
                {deliveryPartners.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: "100%", display: "flex" }}>
            <Button
              style={{
                marginLeft: "auto",
                width: "250px",
                backgroundColor: "#A36E29",
                color: "white",
                fontWeight: "bold",
                fontSize: "1.1rem",
                marginTop: "30px",
                textTransform: "none",
                fontFamily: '"Work Sans", sans-serif',
              }}
              onClick={() => {
                addReturnTrackingDetails();
              }}
            >
              Add Tracking Details
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={refundDialogOpen}
        onClose={() => setRefundDialogOpen(false)}
        maxWidth={"lg"}
        style={{
          width: "100%",
          height: "max-content",
          margin: "auto",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            height: "max-content",
            width: "759px",
            borderRadius: "10px",
            fontFamily: '"Work Sans", sans-serif',
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "2rem",
                fontWeight: 600,
                margin: 0,
              }}
            >
              Refund Details
            </p>
            <IconButton onClick={() => setRefundDialogOpen(false)}>
              <Close />
            </IconButton>
          </div>

          <div style={{ marginTop: "30px" }}>
            {orderDetails
              ?.filter((order) => order.order_detail_id === refundOrderId)
              .map((orderDetails) => (
                <div
                  style={{
                    width: "100%",
                    height: "max-content",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "12px",
                      border: "1px solid #e7e7e7",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/assets/${orderDetails.images[0]["file"]}`}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: "120px",
                      width: "80%",
                      flexDirection: "column",
                      marginLeft: "30px",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: "1.4rem",
                        fontWeight: 600,
                      }}
                    >
                      {orderDetails?.product_name}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        marginTop: "10px",
                        color: "gray",
                        lineHeight: "2rem",
                      }}
                    >
                      Quantity: {orderDetails?.quantity} pcs.
                    </p>
                    <p
                      style={{
                        margin: 0,
                        color: "gray",
                        lineHeight: "2rem",
                      }}
                    >
                      Price: Rs. {orderDetails?.price}
                    </p>
                  </div>
                </div>
              ))}

            <TextField
              fullWidth
              label="Refund Amount"
              variant="outlined"
              type="number"
              value={refundAmount}
              onChange={(e) => setRefundAmount(e.target.value)}
              style={{ marginTop: "20px" }}
            />

            <Button
              variant="contained"
              fullWidth
              style={{
                marginTop: "30px",
                height: "52px",
                backgroundColor: "#A36E29",
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 600,
                fontFamily: '"Work Sans", sans-serif',
              }}
              onClick={createRefund}
            >
              Process Refund
            </Button>
          </div>
        </div>
      </Dialog>

      <Grid
        spacing={5}
        container
        style={{
          paddingTop: "60px",
          paddingLeft: "100px",
          paddingRight: "100px",
          width: "100%",
          height: "max-content",
        }}
      >
        <Grid item xs={12} style={{ height: "100px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <span
              style={{
                width: "300px",
                height: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <ArrowBackIcon
                style={{ fontSize: "3rem", cursor: "pointer" }}
                onClick={() => navigate("/orders")}
              />
              <div>
                <p
                  style={{
                    fontSize: "3rem",
                    margin: 0,
                    lineHeight: 1,
                    marginLeft: "20px",
                  }}
                >
                  {orderDetails[0]?.public_id}
                </p>
                <p
                  style={{
                    margin: 0,
                    color: "gray",
                    fontSize: "1.2rem",
                    marginLeft: "20px",
                    paddingTop: "10px",
                    fontWeight: 600,
                  }}
                >
                  Ordered on :{" "}
                  {new Date(
                    orderDetails[0]?.order_created_at
                  ).toLocaleDateString("en-GB")}
                </p>
              </div>
            </span>
            <div
              style={{
                minWidth: "80px",
                width: "max-content",

                marginLeft: "30px",
                height: "10px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: "5px",
                fontSize: "20px",
                padding: "10px",
                paddingTop: "20px",
                paddingBottom: "20px",
                border: "1px solid #b7b7b7",
              }}
            >
              <span
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  paddingRight: "10px",
                  color:
                    orderDetails[0]?.amount_paid == 0 ? "#cc3521" : "#0D9C00",
                }}
              >
                ●
              </span>
              {orderDetails[0]?.amount_paid == 0 ? "Unpaid" : "Paid"}
            </div>
            {orderDetails.some((order) =>
              [
                "SELLER_VERIFIED",
                "ADMIN_RECEIVED",
                "ADMIN_VERIFIED",
                "ORDER_CREATED",
              ].includes(order.shipment_status)
            ) &&
              (orderDetails.some(
                (order) => order.shipment_status === "SELLER_VERIFIED"
              ) ? (
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "auto",
                    width: "300px",
                    height: "62px",
                    backgroundColor: "#A36E29",
                  }}
                  onClick={() => {
                    setOpenReceivedDialog(true);
                  }}
                >
                  Receive Order
                </Button>
              ) : orderDetails.some(
                  (order) => order.shipment_status === "ADMIN_RECEIVED"
                ) ? (
                <>
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "auto",
                      width: "300px",
                      height: "62px",
                      backgroundColor: "white",
                      borderColor: "#A36E29",
                      color: "#A36E29",
                    }}
                    onClick={() => {
                      setCancleOrderSwitch(true);
                      setOpen(true);
                    }}
                  >
                    Cancel Order
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: "20px",
                      width: "300px",
                      height: "62px",
                      backgroundColor: "#A36E29",
                    }}
                    onClick={() => {
                      setCancleOrderSwitch(false);
                      setOpen(true);
                    }}
                  >
                    FulFill Order
                  </Button>
                </>
              ) : orderDetails.some(
                  (order) => order.shipment_status === "ADMIN_VERIFIED"
                ) ? (
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "auto",
                    width: "300px",
                    height: "62px",
                  }}
                  disabled
                >
                  Fulfill Order
                </Button>
              ) : orderDetails.some(
                  (order) => order.shipment_status === "ORDER_CREATED"
                ) ? (
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "auto",
                    width: "300px",
                    height: "62px",
                  }}
                  disabled
                >
                  Receive Order
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "auto",
                      width: "300px",
                      height: "62px",
                      backgroundColor: "white",
                      borderColor: "#A36E29",
                      color: "#A36E29",
                    }}
                    onClick={() => {
                      setCancleOrderSwitch(true);
                      setOpen(true);
                    }}
                  >
                    Cancel Order
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: "20px",
                      width: "300px",
                      height: "62px",
                      backgroundColor: "#A36E29",
                    }}
                    onClick={() => {
                      setCancleOrderSwitch(false);
                      setOpen(true);
                    }}
                  >
                    FulFill Order
                  </Button>
                </>
              ))}
          </div>
        </Grid>
        <Grid item xs={8}>
          <OrderSummaryComponent
            orderDetails={
              typeof orderDetails === "undefined" || orderDetails === null
                ? []
                : orderDetails
            }
            setRefundDialogOpen={setRefundDialogOpen}
            setRefundOrderId={setRefundOrderId}
            setReturnOrderId={setReturnOrderId}
            setReturnTrackingDialogOpen={setOpenTrackingDialog}
            setReturnReceivedOrderId={setReturnReceivedOrderId}
            setReturnReceived={markReturnReceived}
          />
          <div
            style={{
              padding: "40px",
              paddingTop: "20px",
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                fontSize: "2rem",
                fontWeight: 600,
                marginBottom: "1px",
              }}
            >
              {" "}
              Order Status
            </p>
          </div>
          <TrackOrderComponent
            logs={typeof logs === "undefined" || logs === null ? {} : logs}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomerDetailComponent
            userData={orderDetails[0]?.user_address[0]}
            orderData={orderDetails[0]}
          />
          <SellerDetailComponent orderDetails={orderDetails[0]} />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderDetail;
